@import '../../styles/theme.scss';
@import '../../styles/mixins.scss';
@import '../../styles/media-breakpoints.scss';

.container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    position: relative;
    overflow: hidden;
    background-color: $theme-white;
}

.topSection {
    // background-color: $theme-primary;
    height: 30vh;
    display: flex;
    flex-direction: column-reverse;
    background-image: linear-gradient(to bottom, $theme-secondary, $theme-primary);
    
    h1 {
        text-align: center;
        color: $theme-white;
    }
    
    .waveContainer {
        height: 10vh;
    }
}

.bottomSection {
    background-color: $theme-white;
    height: 70vh;
    width: 100vw;
    overflow-y: scroll;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    
    margin-bottom: 75px;
    
    .bottomItemContainer {
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        margin-bottom: 1em;

        .message {
            color: $theme-dark;
            display: block;
            display: flex;
            align-items: center;
            text-decoration: none;
            border: 1px solid $theme-dark;
            padding: 1em .5em 1em 2em;
            height: 2em;
            width: 80%;
            justify-content: space-between;
            border-radius: 3px;
            max-width: 500px;

            .messageLink {
                color: $theme-dark;
                font-weight: bolder;
                margin-left: 3px;
            }
            
            @include clickable-box-shadow;
        }
    }
    
    .iconContainer {
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        margin-bottom: 1em;
        
        .iconLink {
            color: $theme-dark;
            display: block;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-decoration: none;
            border: 3px solid $theme-dark;
            padding: 5px;
            border-radius: 15px;
            font-weight: bolder;
            width: 90px;
            
            @include clickable-box-shadow;
        }
    }
    
}

.utilContainer {
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    // margin-top: 2em;
    // margin-bottom: 1em;
    background-color: $theme-gray;

    position: fixed;
    bottom: 0px;

    .utilButton {
        display: flex;
        justify-content: center;
        align-items: center;

        color: $theme-dark;
        padding: 3px;
        border: 1px solid $theme-dark;
        margin: 0 5px 0 5px;
        min-height: 56px;
        min-width: 56px;
    }
}
