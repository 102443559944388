@import '../../styles/theme.scss';

$bottom-border-radius: 60px;

.container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    position: relative;
    overflow: hidden;
    background-color: $theme-primary;
}

.topSection {
    // background-color: $theme-primary;
    height: 10vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-image: linear-gradient(to bottom, $theme-secondary, $theme-primary);
    padding: 0 1em 0 1em;
    
    h1 {
        text-align: center;
        color: $theme-white;
    }
}

.bottomSection {
    background-color: $theme-gray;
    height: 90vh;
    width: 100vw;
    overflow-y: scroll;
    
    align-items: center;
    justify-content: center;
}

.datalist {
    padding: 0 1em 1em 1em;
    height: 65vh;
    overflow-y: scroll;

    .logIcon {
        color: gray;
        margin-right: 1em;
    }
    .userLogIcon {
        color: gray;
        margin-left: 1em;
    }
    .date {
        // margin-left: 1em;
        // font-weight: bolder;
        font-size: 12px;
        font-style: italic;
    }

    dt {
        margin-bottom: 3px;
        font-weight: 600;
    }
    
    dd {
        margin-left: 1em;
        margin-bottom: .5em;
    }
    
    dt.isTrainerMessage, dd.isTrainerMessage {
        text-align: right;
    }
}

.messageBoxContainer {
    width: 100vw;
    padding: 5px;

    display: flex;
    flex-direction: column;
    align-items: center;
    
    textarea, button {
        width: 100%;
    }
    
    .messageButtonContainer {
        padding-top: 1em;
        width: 100%;
        
        button {
            width: 100%;
        }
    }
}