@import '../../styles/theme.scss';
@import '../../styles/mixins.scss';


.container {
    background-color: $theme-white;
    border: 2px solid $theme-dark;
    width: 100%;
    height: 100%;
    border-radius: 10px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    @include clickable-box-shadow;
}

.imageContainer {
    width: 30%;
    text-align: center;
    padding: 10px;

    img {
        margin-left: 5px;
        max-width: 100%;
        max-height: 70px;
    }
}

.textContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.textContainer.textContainerRow {
    flex-direction: row;
}

.header {
    text-align: center;
    padding: 10px;
    
    .headerText {
        font-size: 1.5em;
        font-weight: 600;
    }
}

.iconContainer {
    padding: 10px;
}

.descriptionContainer {
    margin: 0;
}