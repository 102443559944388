@import '../../styles/theme.scss';

$bottom-border-radius: 60px;

.container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    position: relative;
    overflow: hidden;
    background-color: $theme-primary;
}

.topSection {
    // background-color: $theme-primary;
    height: 10vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-image: linear-gradient(to bottom, $theme-secondary, $theme-primary);
    padding: 0 1em 0 1em;
    
    h1 {
        text-align: center;
        color: $theme-white;
    }
}

.bottomSection {
    background-color: $theme-gray;
    height: 90vh;
    width: 100vw;
    padding-top: 5vh;
    overflow: scroll;
    
    // display: flex;
    // flex-direction: column;
    align-items: center;
    justify-content: center;
    // flex-wrap: wrap;
    
    border-top-left-radius: $bottom-border-radius;
    border-top-right-radius: $bottom-border-radius;
    
    .cardContainer {
        height: 105px;
        padding: 0 1em 1em 1em;
    }
}


