/* HEX */
$color1: #336699ff;
$color2: #86bbd8ff;
$color3: #2f4858ff;
$color4: #9ee493ff;
$color5: #daf7dcff;


$redish: #de4725;
$yellowish: #d6a044;

$theme-black: #120A2A;
$theme-white: #FFFFFF;
$theme-gray: #f1f1f1;
$theme-gray-light: #f1f1f1;
$theme-green: #007600;

$theme-primary: $color1;
$theme-secondary: $color2;
$theme-dark: $color3;
$theme-accent: $yellowish;

$theme-alt-primary: $color5;
$theme-alt-secondary: $color4;

$theme-background: $theme-gray;
$theme-font-color: $theme-black;

$theme-red: $redish;
