@import "./theme.scss";

@mixin clickable-box-shadow {
    cursor: pointer;
    box-shadow: 2px 2px 1px $theme-dark;
    
    &:active {
        transform: translate(2px, 2px);
    }
}
