@import '../../styles/theme.scss';

.container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    position: relative;
}

.topSectionImageContainer {
    position: absolute;
    height: 40vh;
    width: 100vw;
    z-index: 0;
    
    img {
        width: 100vw;
        height: 40vh;
    }
}

.topSection {
    // background-color: $theme-primary;
    z-index: 1;
    height: 40vh;
    background-image: linear-gradient(to bottom, rgba($theme-primary, 0), $theme-primary);
}

.bottomSection {
    background-color: $theme-primary;
    height: 60vh;
    width: 100vw;
    z-index: 1;
    
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    
    form {
        width: 100%;
        
        .signInFieldset {
            padding: 1em;
        }
        
        .signInButton {
            color: $theme-white;
            border-radius: 4px;
            text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2); 
            background: $theme-secondary;
            font-weight: bolder;
        }
        
        h1 {
            text-align: center;
            color: $theme-white;
        }
    }
}

.signUpLinkContainer {
    
    padding-top: 1em;

    .signUpLink {
        color: $theme-white;
    }

}
