@import '../../styles/theme.scss';

$bottom-border-radius: 60px;

.container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    position: relative;
    overflow: hidden;
    background-color: $theme-primary;
}

.topSection {
    // background-color: $theme-primary;
    height: 10vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-image: linear-gradient(to bottom, $theme-secondary, $theme-primary);
    padding: 0 1em 0 1em;
    
    h1 {
        text-align: center;
        color: $theme-white;
    }
}

.bottomSection {
    background-color: $theme-gray;
    height: 90vh;
    width: 100vw;
    padding-top: 5vh;
    
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    // justify-content: center;
    // flex-wrap: wrap;
    
    border-top-left-radius: $bottom-border-radius;
    border-top-right-radius: $bottom-border-radius;
    
    .cardContainer {
        height: 10vh;
        padding: 0 1em 1em 1em;
    }
}

.noTrainerContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    .noTrainer {
        text-align: center;
    }


    .utilButton {
        display: flex;
        justify-content: center;
        align-items: center;

        color: $theme-dark;
        padding: 3px;
        border: 1px solid $theme-dark;
        margin: 0 5px 0 5px;
        min-height: 56px;
        min-width: 56px;
    }

}