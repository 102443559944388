@import '../../styles/theme.scss';
@import '../../styles/media-breakpoints.scss';

.container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    position: relative;

    h1 {
        text-align: center;
        color: $theme-primary;
        font-size: 5vh;

        @include for-pure-sm-width-up {
            margin: 0;    
        }

        @include for-pure-lg-width-up {
            margin: inherit;    
        }
    }
    
}

.fieldsSection {
    padding: 1em;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    max-height: 100vh;
    overflow-y: scroll;
    z-index: 2;

    .buttonContainer {
        padding-top: 1em;
        position: relative;
        padding-left: 1em;
        padding-right: 1em;

        .signUpButton {
            color: $theme-white;
            border-radius: 4px;
            text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2); 
            background: $theme-primary;
            border: 1px solid $theme-white;
        }
    }
}

.waveContainer2 {
    position: absolute;
    bottom: 2%;
    right: -15%;
    transform: rotate(-20deg);
}
.waveContainer1 {
    position: absolute;
    bottom: -4px;
    width: 100%;
}

.signInLink {
    color: $theme-primary;
    background-color: $theme-white;
    padding: 5px;
}