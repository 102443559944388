@mixin for-pure-sm-width-up {
  @media (min-width: 35.5em) { @content; }
}

@mixin for-pure-md-width-up {
  @media (min-width: 48em) { @content; }
}

@mixin for-pure-lg-width-up {
  @media (min-width: 64em) { @content; }
}

@mixin for-pure-xl-width-up {
  @media (min-width: 80em) { @content; }
}

        
@mixin for-ipad-portrait {
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:portrait) {
    /* For portrait layouts only */
    @content;
  }
}

@mixin for-ipad-landscape {
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:landscape) {
    /* For portrait layouts only */
    @content;
  }
}
