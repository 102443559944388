@import '../../styles/theme.scss';

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    height: 100vh;
    position: relative;
    overflow: hidden;
    background-color: $theme-primary;
    height: 100vh;
    width: 100vw;
}
