@import '../../styles/theme.scss';

$bottom-border-radius: 60px;

.container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    position: relative;
    overflow: hidden;
    background-color: $theme-primary;
}

.topSection {
    // background-color: $theme-primary;
    height: 10vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-image: linear-gradient(to bottom, $theme-secondary, $theme-primary);
    padding: 0 1em 0 1em;
    
    h1 {
        text-align: center;
        color: $theme-white;
    }
}

.bottomSection {
    background-color: $theme-gray;
    height: 90vh;
    width: 100vw;
    padding-top: 5vh;
    overflow: scroll;
    
    align-items: center;
    justify-content: center;
}

.detailsContainer {
    display: flex;
}

.textContainer {
    text-align: center;
    width: 70%;
    
    header {
        font-weight: 600;
        font-size: 18px;
    }
    
    .descContainer {
        font-size: 14px;
    }
}

.imageContainer {
    width: 30%;
    padding-left: 1em;
    
    img {
        width: 100%;
        height: 100%;
    }
    
    .youtubeButton {
        width: 100%;
        height: 100%;
        color: #FF0000;
        
        .sourceName {
            color: $theme-black;
            font-weight: 600;
            display: block;
        }
    }
    .imageButton {
        width: 100%;
        height: 100%;
        background-color: transparent;
        border: none;
        color: #0000FF;

        .sourceName {
            color: $theme-black;
            font-weight: 600;
            display: block;
        }
    }
}

.mediaContainer {
    margin: 5px 5px 0 5px;
    padding-top: 10px;
    overflow-x: scroll;
    
    justify-content: space-between;
    
    .imageContainer {
        padding: 0 5px 5px 0;
    }
}

.setsContainer {
    margin: 5px 5px 0 5px;
    
    padding: 10px 1em 1em 1em;

    overflow-x: scroll;
    
    justify-content: space-between;
    
    .setIconsContainer {
        .buttonContainer {
            text-align: right;       
            // padding-right: 1em;
            
            .completeButton {
                border-color: $theme-green;
                border-radius: 4px;
                text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2); 
                background: $theme-green;
            }
        }
    }
    
    .iconContainer {
        padding: 5px 0 5px 0;
        color: $theme-black;
        cursor: pointer;

        &.completeSet {
            color: $theme-green;
        }
    }
}

.unitInputContainer {
    display: flex;
    align-items: center;
    
    input {
        width: 50px;
    }

    .unitLabel, .repsLabel {
        padding-left: 2px;
        padding-right: 2px;
        font-weight: bolder;
    }
}

.modalContent {
    margin: 2em 0 1em 0;
    height: 70vh;
    width: 80vw;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    img {
        max-width: 100%;
        max-height: 100%;
    }
}